import { Field } from 'react-final-form'

const Error = ({ name, ...rest }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    {...rest}
  >
    {({ meta: { touched, error } }) => (
      touched && error ? <span className="text-xs text-red-600 font-medium">{error}</span> : null
    )}
  </Field>
)

export default Error