import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Button = ({ children, to, icon, size = 'default', kind = 'default', className = '', loading = false, disabled = false, ...rest }) => {
  const sizeMap = {
    lg: 'py-2 px-5 text-base',
    default: 'py-1.5 px-4 text-sm',
    sm: 'py-1 px-3 text-xs',
    xs: 'px-1.5 text-xs',
  }
  const sizeClassName = sizeMap[size]

  const kindMap = {
    default: 'rounded-full transition duration-200 bg-indigo-500 border-2 border-transparent text-white hover:bg-indigo-700',
    outline: 'rounded-full transition duration-200 bg-transparent border-2 border-indigo-500 text-indigo-600 hover:bg-indigo-500 hover:text-white',
    social: 'rounded-full transition duration-200 bg-transparent border-2 border-slate-800 text-slate-800 hover:bg-slate-800 hover:text-white'
  }
  const kindClassName = kindMap[kind]

  const button = <button className={`flex items-center justify-center space-x-2 font-semibold text-center ${kindClassName} ${sizeClassName} ${className} ${(disabled || loading) && 'filter saturate-50'}`} disabled={disabled || loading} {...rest}>
    {loading && <AiOutlineLoading3Quarters className="animate-spin" style={{ lineHeight: '1' }} />}
    <div className={`flex items-center ${icon && 'space-x-1.5'}`}>
      <div className="text-base">{icon}</div>
      <div className="flex items-center space-x-1">{children}</div>
    </div>
  </button>

  return (
    <>
      {to ? <Link to={to}>{button}</Link> : button}
    </>
  )
}

export default Button