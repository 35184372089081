import React, { Children } from 'react'

export default function Alert({ kind = 'error', children, className = '', ...rest }) {
  const kindMap = {
    info: 'bg-sky-100 text-sky-700 font-medium',
    error: 'bg-red-100 text-red-700 font-medium',
  }
  const kindClassName = kindMap[kind]

  return (
    <div className={`flex items-start rounded-2xl space-x-2 px-3 py-2 text-sm ${kindClassName} ${className}`} {...rest}>
      <div className="flex-auto space-y-1">
        {Children.map(children, (child, i) => (
          <div key={i}>{child}</div>
        ))}
      </div>
    </div>
  )
}
