import React from 'react'
import { useAuth } from '../features/auth'
import Login from '../features/auth/Login'

export default function LoginPage() {
  const { authUser } = useAuth()
  console.log('authUser', authUser)

  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 mt-20">
          <div className="col-start-2 col-span-10 sm:col-start-4 sm:col-span-6 lg:col-start-5 lg:col-span-4 space-y-4">
            <div className="text-xl font-semibold text-center">Sign in to your account</div>

            <div className="flex justify-center">
              <Login />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
