import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './features/auth'
import reportWebVitals from './reportWebVitals'

import App from './App'
import SignUpPage from './pages/SignUpPage'
import VerifyAccountPage from './pages/VerifyAccountPage'
import ResendCodePage from './pages/ResendCodePage'
import LoginPage from './pages/LoginPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
  }
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>

        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<LoginPage />} />

            <Route path="register" element={<SignUpPage />} />
            <Route path="verify-account" element={<VerifyAccountPage />} />
            <Route path="resend-code" element={<ResendCodePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
          </Route>
        </Routes>

      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
