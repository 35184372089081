import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '.'
import Alert from '../alert/Alert'
import Button from '../buttons/Button'
import MyField from '../forms/MyField'

export default function Login() {
  const navigate = useNavigate()
  const { login, setAuthUser, forgotPassword } = useAuth()
  const [submitError, setSubmitError] = useState(null)
  const navigateToApp = () => (window.location.href = process.env.REACT_APP_WEBAPP_URL)
  const location = useLocation()
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email)
    }
  }, [location])

  const onSubmit = async (values) => {
    setSubmitError(null)
    console.log('values', values)
    try {
      const user = await login(values.email, values.password)
      setAuthUser(user)

      navigateToApp()
    } catch (err) {
      console.log('Error logging in: ', err)
      if (err.message.includes('User does not exist')) {
        setSubmitError('No account with this email exists')
      } else {
        setSubmitError('Invalid email or password')
      }
    }
  }

  return (
    <div className="w-full">
      {submitError && (
        <div className="my-4">
          <Alert>{submitError}</Alert>
        </div>
      )}

      <Form
        className="w-full"
        onSubmit={onSubmit}
        initialValues={{
          email,
          password: '',
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="space-y-3 mb-4">
              <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
              <MyField
                name="password"
                label="Password"
                type="password"
                component="input"
                placeholder="securePassword123!"
              />
            </div>

            <div className="flex items-center justify-between">
              <Button loading={submitting}>Login</Button>
              <a className="text-sm" onClick={() => navigate('/forgot-password')}>
                Forgot password?
              </a>
            </div>
          </form>
        )}
      </Form>

      <div className="my-6">
        <div className="border-t border-slate-200 mb-6" />
        {/* <SocialLogin>Sign in with Google</SocialLogin> */}
      </div>

      <div className="my-6">
        <div className="text-sm text-center">
          Don't have an account? <Link to="/register">Sign up</Link>
        </div>
      </div>
    </div>
  )
}
