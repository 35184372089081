import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '.'
import Alert from '../alert/Alert'
import Button from '../buttons/Button'
import MyField from '../forms/MyField'
import { includesUpperCase, includesLowerCase, includesNumeric, includesSpecial } from './validations'

export default function SignUp() {
  const { signUp, setAuthUser } = useAuth()
  const [email, setEmail] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const navigate = useNavigate()

  const onSignUpSubmit = async (values) => {
    setSubmitError(null)
    console.log('values', values)
    try {
      const user = await signUp(values.email, values.password)
      setAuthUser(user)
      setEmail(values.email)
      navigate('/verify-account', { state: { email: values.email } })
    } catch (err) {
      console.log('Error signing up: ', err)
      setSubmitError('Something went wrong - please try again later')
    }
  }

  return (
    <div className="w-full">
      {submitError && (
        <div className="my-4">
          <Alert>{submitError}</Alert>
        </div>
      )}

      <Form
        onSubmit={onSignUpSubmit}
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          if (!values.password) {
            errors.password = 'Required'
          }
          if (values.password?.length < 8) {
            errors.password = 'Password should be at least 8 letters'
          }
          if (!includesUpperCase(values.password)) {
            errors.password = 'Password should include at least one upper case letter'
          }
          if (!includesLowerCase(values.password)) {
            errors.password = 'Password should include at least one lower case letter'
          }
          if (!includesNumeric(values.password)) {
            errors.password = 'Password should include at least one numeric letter'
          }
          if (!includesSpecial(values.password)) {
            errors.password = 'Password should include at least one special letter (^$*.[]{}()?"!@#%&/,><\':;|_~`=+-)'
          }
          return errors
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="space-y-3 mb-4">
              <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
              <MyField
                name="password"
                label="Password"
                type="password"
                component="input"
                placeholder="securePassword123!"
              />
            </div>

            <Button loading={submitting}>Sign up</Button>
          </form>
        )}
      </Form>

      {/* <div className="my-6">
        <div className="border-t border-slate-200 mb-6" />
        <SocialLogin>Sign up with Google</SocialLogin>
      </div> */}
    </div>
  )
}
