import React, { useState } from 'react'
import * as R from 'ramda'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '.'
import Alert from '../alert/Alert'
import Button from '../buttons/Button'
import MyField from '../forms/MyField'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const { forgotPassword, forgotPasswordSubmit } = useAuth()
  const [codeSent, setCodeSent] = useState(false)
  const [email, setEmail] = useState('')
  const [submitError, setSubmitError] = useState(null)

  const onFirstSubmit = async (values) => {
    setSubmitError(null)
    setEmail(values.email)
    console.log('values', values)
    try {
      await forgotPassword(values.email)
      setCodeSent(true)
    } catch (err) {
      console.log('Error sending password reset code: ', err)
      setSubmitError('Something went wrong - please try again later')
    }
  }

  const onFinalSubmit = async (values) => {
    setSubmitError(null)
    console.log('values', values)
    try {
      await forgotPasswordSubmit(values.email, values.code, values.newPassword)
      navigate('/login')
    } catch (err) {
      console.log('Error resetting the password: ', err)
      console.log('test', R.path(['response', 'data', 'error', 'code'])(err))
      if (R.path(['response', 'data', 'error', 'code'])(err) === 'CodeMismatchException') {
        setSubmitError('Invalid password reset code.')
      } else {
        setSubmitError('Something went wrong - please try again later')
      }
    }
  }

  return (
    <div className="w-full">
      {submitError && (
        <div className="my-4">
          <Alert>{submitError}</Alert>
        </div>
      )}
      {!codeSent && (
        <div className="text-sm text-slate-700 my-4">
          Enter the email associated with your account and we'll send you a code to reset your password.
        </div>
      )}
      {codeSent && (
        <div className="my-4">
          <Alert kind="info">Please check your email for the reset code.</Alert>
        </div>
      )}

      {!codeSent && (
        <Form
          className="w-full"
          onSubmit={onFirstSubmit}
          initialValues={{
            email: '',
          }}
          validate={(values) => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Required'
            }
            return errors
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="space-y-3 mb-4">
                <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
              </div>

              <div className="flex items-center justify-between">
                <Button loading={submitting}>Continue</Button>
                <a className="text-sm" onClick={() => navigate('/login')}>
                  Return to login
                </a>
              </div>
            </form>
          )}
        </Form>
      )}

      {codeSent && (
        <Form
          className="w-full"
          onSubmit={onFinalSubmit}
          initialValues={{
            email,
            code: '',
            newPassword: '',
          }}
          validate={(values) => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Required'
            }
            return errors
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="space-y-3 mb-4">
                <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
                <MyField name="code" label="Code" type="text" component="input" placeholder="Code" />
                <MyField
                  name="newPassword"
                  label="New password"
                  type="password"
                  component="input"
                  placeholder="newSecurePassword123!"
                />
              </div>

              <div className="flex items-center justify-between">
                <Button loading={submitting}>Reset password</Button>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  )
}
