import axios from 'axios'
import { apiBaseUrl, getCsrfToken } from '../../utils/global'

export const cognito = {
  currentAuthenticatedUser: async () => {
    const { data } = await axios.get(`${apiBaseUrl}/auth/current-user`)
    return data
  },
  currentSession: async () => {
    const { data } = await axios.get(`${apiBaseUrl}/auth/refresh-session`)
    return data    
  },
  signUp: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/register`, payload)
    return data
  },
  confirmSignUp: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/confirm-registration`, payload)
    return data    
  },
  resendSignUp: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/resend-confirmation-code`, payload)
    return data    
  },
  signIn: async (payload) => { 
    await getCsrfToken()   
    const { data } = await axios.post(`${apiBaseUrl}/auth/login`, payload)
    return data    
  },
  completeNewPassword: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/complete-new-password`, payload)
    return data    
  },
  signOut: async () => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/logout`)
    return data    
  },
  forgotPassword: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/forgot-password`, payload)
    return data
  },
  forgotPasswordSubmit: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/confirm-password`, payload)
    return data
  },
  changePassword: async (payload) => {
    await getCsrfToken()
    const { data } = await axios.post(`${apiBaseUrl}/auth/change-password`, payload)
    return data
  },
}