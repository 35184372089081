import { createContext, useEffect, useState, useContext } from 'react'
import { Auth } from 'aws-amplify'
import { cognito } from './cognito'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    const fn = async () => {
      try {
        // Make sure to set up withCredentials with axios to enable cookies
        initCookieSetup()

        // Check if session already exists
        const user = await cognito.currentAuthenticatedUser()
        console.log('user', user)
        setAuthUser(user)
        setIsAuthenticating(false)

        // Redirect to the app
        window.location.href = process.env.REACT_APP_WEBAPP_URL
      } catch (err) {
        // Not signed in
        setIsAuthenticating(false)

        // If not authenticated, redirect to login
        navigate('/login')
        return
      }
    }
    fn()
  }, [])

  const initCookieSetup = () => {
    axios.defaults.withCredentials = true
  }

  const signUp = async (username, password) => {
    const { user } = await cognito.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    })
    return user
  }

  const confirmSignUp = async (username, code) => {
    await cognito.confirmSignUp({ username, code })
  }

  const resendConfirmationCode = async (username) => {
    await cognito.resendSignUp({ username })
  }

  const login = async (username, password, newPassword) => {
    const user = await cognito.signIn({ username, password })

    // Handle first sign-in after forgot password or sign up by admin
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const _user = await cognito.completeNewPassword({ username, attributes: user.attributes, newPassword })
      return _user
    }

    return user
  }

  const logout = async () => {
    await cognito.signOut()
  }

  const federatedLogin = ({ provider }) => {
    if (!provider) {
      console.log('Identity provider required')
      return
    }
    const identityProviderMap = {
      google: CognitoHostedUIIdentityProvider.Google
    }
    const identityProvider = identityProviderMap[provider]

    if (!identityProvider) {
      console.log('Invalid identity provider')
      return
    }
    Auth.federatedSignIn({ provider: identityProvider })
  }

  /**
   * Get JWT accessToken, idToken, and refreshToken
   * 
   *    NOTE: This method will automatically refresh the accessToken and idToken if tokens are expired and 
   *    a valid refreshToken presented. So you can use this method to refresh the session if needed.
   */
  const getCurrentSession = async () => {
    return await cognito.currentSession()
  }

  const getAccessToken = async () => {
    const { accessToken } = await cognito.currentSession()
    return accessToken
  }
  
  // Sends a confirmation code to user's email
  const forgotPassword = async (username) => {
    await cognito.forgotPassword({ username })
  }

  // Collect confirmation code and new password, then submit them
  const forgotPasswordSubmit = async (username, code, newPassword) => {
    await cognito.forgotPasswordSubmit({ username, code, newPassword })
  }

  const changePassword = async (username, oldPassword, newPassword) => {
    await cognito.changePassword({ username, oldPassword, newPassword })
  }

  const value = {
    isAuthenticating,
    setIsAuthenticating,
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    login,
    logout,
    authUser,
    setAuthUser,
    federatedLogin,
    getCurrentSession,
    getAccessToken,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}


