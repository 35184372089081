import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '.'
import { createUserMachine } from '../../utils/global'
import Alert from '../alert/Alert'
import Button from '../buttons/Button'
import MyField from '../forms/MyField'

export default function VerifyAccount() {
  const { setAuthUser, confirmSignUp, getAccessToken } = useAuth()
  const [submitError, setSubmitError] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')

  useEffect(() => {
    console.log('location.state', location.state)
    if (location.state?.email) {
      setEmail(location.state.email)
    }
  }, [location])

  const onVerificationSubmit = async (values) => {
    setSubmitError(null)
    console.log('values', values)
    try {
      const user = await confirmSignUp(values.email, values.code)
      console.log('user in onVerificationSubmit', user)
      setAuthUser(user)

      navigate('/login', { state: { email } })
    } catch (err) {
      console.log('Error logging in: ', err)
      setSubmitError('Something went wrong - please try again later')
    }
  }

  return (
    <div className="w-full">
      {submitError && (
        <div className="my-4">
          <Alert>{submitError}</Alert>
        </div>
      )}
      <div className="my-4">
        <Alert kind="info">Please check your email for verification code.</Alert>
      </div>

      <Form
        onSubmit={onVerificationSubmit}
        initialValues={{
          email,
          code: '',
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          if (!values.code) {
            errors.code = 'Required'
          }
          return errors
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="space-y-3 mb-4">
              <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
              <MyField name="code" label="Verification Code" type="text" component="input" placeholder="123456" />
            </div>

            <div className="flex items-center justify-between">
              <Button loading={submitting}>Verify account</Button>
              <a className="text-sm" onClick={() => navigate('/resend-code', { state: { email: values.email } })}>
                Resend code
              </a>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
