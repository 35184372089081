import React from 'react'
import { Field } from 'react-final-form'
import Label from './Label'
import Error from './Error'

const MyField = ({ label, name, prefix, className = '', ...rest }) => {
  return (
    <div>
      {label && <Label className="block mb-1">{label}</Label>}
      <div className="flex items-center">
        {prefix && <div className="text-sm font-medium mr-1.5">{prefix}</div>}
        <Field name={name} className={`w-full field ${className}`} {...rest} />
      </div>
      <div><Error name={name} /></div>
    </div>
  )
}

export default MyField