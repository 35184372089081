import React from 'react'
import SignUp from '../features/auth/SignUp'

export default function SignUpPage() {
  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 mt-20">
          <div className="col-start-2 col-span-10 sm:col-start-4 sm:col-span-6 lg:col-start-5 lg:col-span-4 space-y-4">
            <div className="text-xl font-semibold text-center">Create your account</div>

            <div className="flex justify-center">
              <SignUp />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
