import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '.'
import Alert from '../alert/Alert'
import Button from '../buttons/Button'
import MyField from '../forms/MyField'

export default function ResendCode() {
  const { resendConfirmationCode } = useAuth()
  const [submitError, setSubmitError] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email)
    }
  }, [location])

  const onResendCodeSubmit = async (values) => {
    setSubmitError(null)
    console.log('values', values)
    try {
      await resendConfirmationCode(values.email)
      navigate('/verify-account', { state: { email: values.email } })
    } catch (err) {
      console.log('Error logging in: ', err)
      setSubmitError('Something went wrong - please try again later')
    }
  }

  return (
    <div className="w-full">
      {submitError && (
        <div className="my-4">
          <Alert>{submitError}</Alert>
        </div>
      )}

      <Form
        onSubmit={onResendCodeSubmit}
        initialValues={{
          email,
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          return errors
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="space-y-3 mb-4">
              <MyField name="email" label="Email" type="text" component="input" placeholder="hello@example.com" />
            </div>

            <div className="flex items-center justify-between">
              <Button loading={submitting}>Resend verification code</Button>
              <a className="text-sm" onClick={() => navigate('/verify-account')}>
                Back to verification
              </a>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
